import React, { useEffect, useState } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Website from './pages/website/website';
import Terms from './pages/terms';
import Services from './pages/services';
import About from './pages/about';
import Blog from './pages/blog';
import Post from './pages/post';
import Contact from './pages/contact';
import Navbar from './pages/website/components/navbar';
import Footer from './pages/website/components/footer';
import Book from './pages/book/book';
// import Validate from './pages/book/validate';
import NotFoundPage from './pages/notFoundPage';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './pages/firebase';
import Loading from './components/loading';

function BookLayout({ children }: any) {
	return (
		<>
			{children}
			<div className='w-full relative'>
				<p className="text-[1.2rem] my-10 text-center">
					Powered by{" "}
					<a href="https://visiond.pt" className="hover:underline font-bold">
						Vision D
					</a>
				</p>
				<p onClick={() => window.location.href = "/terms"} className='cursor-pointer text-center hover:underline'>Terms & Conditions</p>
				<p className='w-full text-center'>© All rights reserved. Sky Park 2023 </p>
			</div>
		</>
	)
}

function FooterLayout({ children }: any) {
	return (
		<>
			<Navbar />
			<div className='pt-[4rem]'>
				{children}
				<Footer />
			</div>
		</>
	)
}

function App() {

	const [textsHome, setTextsHome] = useState<any>(null);
	const [textsAbout, setTextsAbout] = useState<any>(null);
	const [textsTerms, setTextsTerms] = useState<any>(null);

    useEffect(() => {
        async function fetchTexts() {
            try {
                let docRef = doc(db, 'skypark', 'home');
                let docSnap = await getDoc(docRef);
                if (docSnap.exists()) setTextsHome(docSnap.data());
				docRef = doc(db, 'skypark', 'about');
                docSnap = await getDoc(docRef);
                if (docSnap.exists()) setTextsAbout(docSnap.data());
				docRef = doc(db, 'skypark', 'terms');
                docSnap = await getDoc(docRef);
                if (docSnap.exists()) setTextsTerms(docSnap.data());
            } catch (error) {
                console.error("Error getting document: ", error);
            }
        }
        fetchTexts();
    }, []);

    if (!textsHome || !textsAbout || !textsTerms) {
        return (
            <Loading />
        )
    }
	
	return (
		<div id='app' className='relative'>
			<BrowserRouter>
				<Routes>
					<Route index element={<FooterLayout><Website texts={textsHome} /></FooterLayout>} />
					<Route path="/terms" element={<FooterLayout><Terms texts={textsTerms} /></FooterLayout>} />
					<Route path="/contact" element={<FooterLayout><Contact /></FooterLayout>} />
					<Route path="/services" element={<FooterLayout><Services texts={textsHome?.services} /></FooterLayout>} />
					<Route path="/about" element={<FooterLayout><About texts={textsAbout} /></FooterLayout>} />
					<Route path="/blog" element={<FooterLayout><Blog /></FooterLayout>} />
					<Route path="/post/:titulo" element={<FooterLayout><Post /></FooterLayout>} />
					<Route path="/book" element={<BookLayout><Book /></BookLayout>} />
					{/* <Route path="/validate" element={<BookLayout><Validate /></BookLayout>} /> */}
					{/* <Route path="/validate/:id" element={<BookLayout><Validate /></BookLayout>} /> */}
					<Route path="*" element={<BookLayout><NotFoundPage /></BookLayout>} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
