import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Logo from "../../content/images/logo.png";
import FirstForm from "./components/firstForm";
import SecondForm from "./components/secondForm";
import Summary from "./components/summary";
import Pdf from "./components/pdf";
import emailjs from 'emailjs-com';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db } from "../firebase";

export default function Home() {
    const navigate = useNavigate();
    const [lang, setLang] = useState("pt");
    const [form, setForm] = useState(1);
    const [docRef, setDocRef] = useState<any>(null);

    const [formData, setFormData] = useState<any>({
        // First Form
        idClient: "0",
        city: "",
        parking: "",
        delivery: "",
        deliveryLocation: "",
        extraServices: [],
        returnFlight: "",
        checkinDate: (new Date()).toISOString().substring(0, 10),
        checkoutDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0],
        inputTimeHours: "",
        inputTimeMinutes: "",
        outputTimeHours: "",
        outputTimeMinutes: "",
        bookingPrice: 0,
        licensePlate: "",
        remarks: "",
        // Second Form
        brand: "",
        model: "",
        color: "",
        name: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        taxName: "",
        taxNumber: "",
        terms: false
    });

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    useEffect(() => {
        const checkLicensePlateInDatabase = async () => {
            if (form !== 2 || formData.brand !== '') return
            const clientsCollectionPath = `${formData?.city}/skypark/clients`;
            const clientsCollectionRef = collection(db, clientsCollectionPath);
            const q = query(clientsCollectionRef, where("licensePlate", "==", formData.licensePlate.toUpperCase()));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) return;
            if ((lang === 'pt' && window.confirm('A sua matrícula já se encontra no sistema. Quer usar os mesmos dados?'))
                || (lang !== 'pt' && window.confirm('Your license plate is in our system. Do you want to use the saved data?'))) {
                const docData = querySnapshot.docs[0].data();
                setFormData((prev: any) => ({
                    ...prev,
                    brand: (docData.carInfo) ? (docData.carInfo).split(', ')[0] : prev.brand,
                    model: (docData.carInfo) ? (docData.carInfo).split(', ')[1] : prev.model,
                    color: (docData.carInfo) ? (docData.carInfo).split(', ')[2] : prev.color,
                    name: docData.name || prev.name,
                    lastName: docData.lastname || prev.lastName,
                    email: docData.email || prev.email,
                    phoneNumber: docData.phoneNumber || prev.phoneNumber,
                    taxName: docData.taxName || prev.taxName,
                    taxNumber: docData.taxNumber || prev.taxNumber,
                }));
            }
        };

        checkLicensePlateInDatabase().catch(console.error);
    }, [form, formData.licensePlate, formData.brand, lang, formData?.city]);

    // Book after PDF is done
    useEffect(() => {

        function getDateFormat(date: Date) {
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
            const yyyy = date.getFullYear();

            return `${dd}/${mm}/${yyyy}`;
        }

        function getCheckIn() {
            let checkInDate = getDateFormat(new Date(formData.checkinDate));
            let hours = formData.inputTimeHours.toString().padStart(2, '0');
            let minutes = formData.inputTimeMinutes.toString().padStart(2, '0');
            return `${checkInDate}, ${hours}:${minutes}`;
        }


        function getDateFormatWithTime(date: Date) {
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
            const yyyy = date.getFullYear();
            const hours = date.getHours()
            const minutes = date.getMinutes();

            return `${dd}/${mm}/${yyyy}, ${hours}:${minutes}`;
        }

        function getExtraServices(language: string) {
            let newExtraServices: { pt: string; price: number; checked: boolean, type:number }[] = [];
            formData.extraServices.forEach((service: any) => {
                newExtraServices.push({
                    pt: service[language],
                    price: service?.price || 0,
                    checked: false,
                    type: service?.type || -1
                });
            });
            return newExtraServices;
        }
        
        function getExtraServicesNames(language: string) {
            let newExtraServices: string[] = [];
            formData.extraServices.forEach((service: any) => {
                newExtraServices.push(service[language]);
            });
            return newExtraServices;
        }

        function getCheckOut() {
            let checkOutDate = getDateFormat(new Date(formData.checkoutDate));
            let hours = formData.outputTimeHours.toString().padStart(2, '0');
            let minutes = formData.outputTimeMinutes.toString().padStart(2, '0');
            return `${checkOutDate}, ${hours}:${minutes}`;
        }

        async function alocate() {
            const alocationsUsed: any = [];
            const parkDocRef = doc(db, formData.city, 'skypark');
            const clientsColRef = collection(parkDocRef, 'clients');

            // Get alocations unavailable 
            const snapshot = await getDocs(clientsColRef);
            snapshot.forEach((doc) => {
                const clientData: any = doc.data();
                if (clientData.alocation && (clientData.stats === 'reservado' || clientData.stats === 'recolhido')) {
                    alocationsUsed.push(clientData.alocation);
                }
            });

            // Alocate based on booking details
            const parkDoc = await getDoc(parkDocRef);
            if (parkDoc.exists()) {
                const parkData = parkDoc.data();

                let alocations;
                if (formData.parking['pt'] === 'Coberto') alocations = parkData.alocations.covered
                else alocations = parkData.alocations.uncovered;

                let newAlocation = alocations.min;
                while (newAlocation <= alocations.max) {
                    if (!alocationsUsed.includes(newAlocation))
                        break;
                    newAlocation++;
                }
                if (newAlocation > alocations.max) return '';
                return newAlocation;
            }
        }

        function getAlocation() {
            const checkinDate = new Date(formData.checkinDate);
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);
            const isToday = checkinDate.getTime() === today.getTime();
            const isTomorrow = checkinDate.getTime() === tomorrow.getTime();
            // Alocate if checkIn is today or tomorrow
            if (isToday || isTomorrow) return alocate();
            else return '';
        }

        async function saveBooking() {
            const newAlocation = await getAlocation()
            let newUser = {
                parkBrand: 'Skypark',
                stats: 'reservado',
                bookingDate: getDateFormatWithTime(new Date()),
                idClient: formData.idClient,
                city: formData.city,
                parkingType: formData.parking['pt'],
                parkingPrice: formData.parking.price,
                checkOut: getCheckOut(),
                checkIn: getCheckIn(),
                carInfo: `${formData?.brand}, ${formData?.model}, ${formData?.color}`,
                extraServices: getExtraServices('pt'),
                bookingPrice: formData.bookingPrice,
                deliveryName: formData.delivery['pt'],
                deliveryLocation: formData.delivery.location,
                action: 'reserva',
                actionDate: getDateFormatWithTime(new Date()),
                actionUser: 'client',
                name: formData.name,
                lastname: formData.lastName,
                email: formData.email,
                returnFlight: formData.returnFlight,
                licensePlate: (formData.licensePlate).toUpperCase(),
                phoneNumber: formData.phoneNumber,
                taxName: formData.taxName,
                taxNumber: formData.taxNumber,
                eval: true,
                bookingRemarks: formData.remarks,
                // Others
                alocation: newAlocation,
                park: '',
                row: '',
                spot: '',
                paymentMethod: '',
                correction: 0,
                ocorrenceRemarks: '',
                cancelRemarks: '',
            };
            try {
                await setDoc(doc(db, `${formData.city}/skypark/clients`, formData.idClient), newUser);
                const newHistoryDocRef = doc(db, `${formData.city}/skypark/clients/${formData.idClient}/history`, (new Date()).toISOString());
                await setDoc(newHistoryDocRef, newUser).then(async () => {
                    let emailParamsAdmin: any = { ...newUser, pdf: docRef, email: "skypark.officer@gmail.com" };
                    emailjs.send('service_qctwd7l', 'template_q18w3dn', emailParamsAdmin, 'jeStFGZ-400kFvT_-');
                    let emailParamsClient: any = { ...newUser, delivery: formData.delivery[lang], parking: formData.parking[lang], extraServices: getExtraServicesNames(lang), pdf: docRef, brand: 'Skypark' };
                    emailjs.send('service_qctwd7l', 'template_yfa3omo', emailParamsClient, 'jeStFGZ-400kFvT_-');
                    await displayModal("Aviso", "Formulário enviado com sucesso! Verifique o seu email.");
                    navigate('/');
                })
            } catch (err) {
                console.log(err)
                await displayModal("Aviso", "Ocorreu um erro. Por favor, tente novamente mais tarde.");
            }
        }
        if (docRef) saveBooking();
    }, [docRef, formData, navigate, lang]);

    const changeLanguage = (lang: string) => {
        localStorage.setItem("lang", lang);
        setLang(lang);
    };

    function displayModal(title: string, message: string) {
        return new Promise((resolve) => {
            let modal = document.getElementById("modal");
            let modalTitle = document.getElementById("modal-title");
            let modalText = document.getElementById("modal-text");
            let btn = document.getElementById("confirmBtn");
            if (!modal || !modalTitle || !modalText || !btn) {
                resolve(false);
                return;
            }
            modalTitle.textContent = title;
            modalText.textContent = message;
            modal.classList.replace('hidden', 'block');
            btn.onclick = function () {
                if (!modal) {
                    resolve(false);
                    return;
                }
                modal.classList.replace('block', 'hidden');
                resolve(true);
            };
        });
    };

    async function nextForm() {
        if (form === 1) {
            if (
                !formData.parking ||
                !formData.delivery ||
                (!formData.extraServices || formData.extraServices.length === 0) ||
                !formData.returnFlight.trim() ||
                !formData.checkinDate.trim() ||
                !formData.checkoutDate.trim() ||
                (!formData.inputTimeHours.trim() || isNaN(Number(formData.inputTimeHours))) ||
                (!formData.inputTimeMinutes.trim() || isNaN(Number(formData.inputTimeMinutes))) ||
                (!formData.outputTimeHours.trim() || isNaN(Number(formData.outputTimeHours))) ||
                (!formData.outputTimeMinutes.trim() || isNaN(Number(formData.outputTimeMinutes)))
            ) {
                await displayModal("Aviso", "Por favor, preencha todos os campos.");
                return;
            }
            const pattern = /^[A-Z0-9]{2,3}-?[A-Z0-9]{2,3}-?[A-Z0-9]{2,3}$/i;
            if (!formData.licensePlate.trim() || !pattern.test(formData.licensePlate)) {
                await displayModal("Aviso", "Por favor, insira uma matrícula válida.");
                return;
            }
            const now = new Date();
            const checkin = new Date(formData.checkinDate);
            checkin.setHours(parseInt(formData.inputTimeHours, 10), parseInt(formData.inputTimeMinutes, 10));

            const departure = new Date(formData.checkoutDate);
            departure.setHours(parseInt(formData.outputTimeHours, 10), parseInt(formData.outputTimeMinutes, 10));

            const isDateBeforeToday = (date: Date) => new Date(date.toDateString()) < new Date(now.toDateString());

            const isDateTimeBeforeNow = (dateTime: Date) => dateTime < now;

            if (isDateBeforeToday(checkin) || (isDateTimeBeforeNow(checkin) && checkin.toDateString() === now.toDateString())) {
                await displayModal("Aviso", "A data de checkIn deve ser no futuro.");
                return;
            }
            if (isDateBeforeToday(departure) || (isDateTimeBeforeNow(departure) && departure.toDateString() === now.toDateString())) {
                await displayModal("Aviso", "A data de partida deve ser no futuro.");
                return;
            }
            if (departure <= checkin) {
                await displayModal("Aviso", "A data de partida deve ser posterior à data de check-in.");
                return;
            }
            setForm(2);
        } else if (form === 2) {
            if (!formData.brand.trim() || !formData.model.trim() || !formData.color.trim() || !formData.name.trim() || !formData.lastName.trim() || !formData.phoneNumber.trim()) {
                await displayModal("Aviso", "Por favor, preencha todos os campos.");
                return;
            }
            if (!formData.email || !formData.email.trim() || !formData.email.match(/^[^ ]+@[^ ]+\.[a-z]{2,3}$/)) {
                await displayModal("Aviso", "Por favor, insira um e-mail válido.");
                return;
            }
            if (formData.taxName || formData.taxNumber) {
                if (!formData.taxName.trim()) {
                    await displayModal("Aviso", "Por favor, insira o nome para a fatura.");
                    return;
                }

                if (!formData.taxNumber.trim() || isNaN(Number(formData.taxNumber))) {
                    await displayModal("Aviso", "Por favor, insira um número de contribuinte válido.");
                    return;
                }
            }
            setForm(3);
        }
        window.scrollTo(0, 0);
    }

    async function getUserId() {
        try {
            const querySnapshot = await getDocs(query(collection(db, `${formData.city}/skypark/clients`)));
            const documents = querySnapshot.docs.filter(doc => parseInt(doc.id, 10).toString() !== 'NaN').map(doc => parseInt(doc.id, 10));
            // Skypark
            setFormData((prev: any) => ({ ...prev, idClient: `${(Math.max(...documents) + 1).toString()}` }));
        } catch (error) {
            console.error("Error fetching documents:", error);
        }
    }

    async function book() {
        if (!formData.terms) {
            await displayModal("Aviso", "Para continuar deve aceitar os T&C.");
            return;
        }
        await getUserId();
        setForm(4);
        window.scrollTo(0, 0);
    }

    return (
        <div id="home" className="flex flex-col justify-between min-h-screen items-center bg-[var(--bg)]">
            <div id="modal" className="fixed inset-0 z-10 hidden bg-black bg-opacity-70">
                <div className="modal-content absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-64 h-64 text-white bg-primary border border-gray-300 p-5 flex flex-col items-center justify-center rounded-3xl bg-[var(--primary)]">
                    <p id="modal-title" className="mb-4 font-bold"></p>
                    <p id="modal-text" className="text-center text-balance"></p>
                    <button id="confirmBtn" className="h-8 mt-4 text-white transition duration-200 ease-in bg-transparent border border-white rounded-full outline-none w-28 hover:bg-white hover:text-primary focus:outline-none hover:text-black">OK</button>
                </div>
            </div>
            <div className="w-full px-[6vw] sete:w-[30rem] sete:px-0">
                <img onClick={() => navigate('/')} src={Logo} alt="logo" className="w-[40rem] cursor-pointer" />
                <div className="space-y-4  text-[1rem]" >
                    {/* Parking Options */}
                    <div className="flex rounded-full h-[3rem] my-4 w-full border-2 border-black items-center justify-around">
                        <div
                            onClick={() => changeLanguage("pt")}
                            className={`cursor-pointer w-full items-center flex justify-center rounded-full mx-4 ${lang === "pt" ? "bg-[var(--primary)] text-white" : ""}`}>
                            Português
                        </div>
                        <div
                            onClick={() => changeLanguage("en")}
                            className={`cursor-pointer w-full items-center flex justify-center rounded-full mx-4 ${lang === "en" ? "bg-[var(--primary)] text-white" : ""}`}>
                            English
                        </div>
                    </div>
                    {form === 1 && <FirstForm formData={formData} setFormData={setFormData} lang={lang} />}
                    {form === 2 && <SecondForm formData={formData} setFormData={setFormData} lang={lang} />}
                    {form === 3 && <Summary formData={formData} setFormData={setFormData} lang={lang} book={book} />}
                    {form === 4 &&
                        <Pdf formData={formData} setDocRef={setDocRef} lang={lang} />
                    }
                    <div className="flex justify-center w-full gap-8 pt-8">
                        {form === 1 && <button className="bg-[var(--primary)] text-white px-16 py-2 rounded-full" onClick={nextForm}>{lang === 'pt' ? 'Seguinte' : 'Next'}</button>}
                        {form === 2 &&
                            <>
                                <button className="border-2 border-black w-full py-2 rounded-full transition-all hover:bg-[rgba(.5,.5,.5,.1)]" onClick={() => { setForm(1); window.scrollTo(0, 0); }}>{lang === 'pt' ? 'Voltar' : 'Go back'}</button>
                                <button className="bg-[var(--primary)] border-2 border-[transparent] text-white w-full py-2 rounded-full transition-all hover:brightness-90" onClick={nextForm}>{lang === 'pt' ? 'Ver resumo' : 'See summary'}</button>
                            </>
                        }
                        {form === 3 &&
                            <>
                                <button className="border-2 border-black w-full py-2 rounded-full transition-all hover:bg-[rgba(.5,.5,.5,.1)]" onClick={() => { setForm(2); window.scrollTo(0, 0); }}>{lang === 'pt' ? 'Voltar' : 'Go back'}</button>
                                <button className="bg-[var(--primary)] border-2 border-[transparent] text-white w-full py-2 rounded-full transition-all hover:brightness-90" onClick={book}>{lang === 'pt' ? 'Reservar' : 'Book'}</button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
