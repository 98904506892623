import React, { useEffect, useState } from 'react';

import LightIcon from '../../../content/icons/light.png';
import WashIcon from '../../../content/icons/wash.png';
import AnaliseIcon from '../../../content/icons/analise.png';
import CareIcon from '../../../content/icons/care.png';


export default function Extras({ texts }: any) {
    const [lang, setLang] = useState("pt");

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    function ExtraService({ ...props }) {
        return (
            <div className='w-full flex nove:flex-row flex-col gap-[2rem] justify-between items-center'>
                <div className='flex nove:flex-row flex-col gap-8 items-center nove:items-start'>
                    <div className='shrink-0 w-[10rem] h-[10rem] rounded-[35px] bg-[var(--primary)]'>
                        <img src={props.src} alt="icon" className='w-full h-full' />
                    </div>
                    <div>
                        <h4 className='text-[1.5rem] mil:text-[2rem] font-bold'>{props.title}</h4>
                        <p className='text-[1.2rem] mil:text-[1.5rem]'>{props.text}</p>
                    </div>
                </div>
                <div className='h-full flex flex-col items-center gap-4'>
                    <p className='font-bold text-[2rem]'>{props.price}€</p>
                    <button className=' border-4 border-[var(--primary)] rounded-[10px] w-[15rem] py-4 font-bold text-white hover:bg-white hover:text-black transition-all duration-300' onClick={() => window.location.href="/book"}>{lang === 'pt' ? 'RESERVAR' : 'BOOK'}</button>
                </div>
            </div>
        )
    }

    return (
        <div className='relative min-h-screen bg-black px-[8vw] pb-[5rem]'>
            <h3 className='text-[2rem] text-[var(--primary)] font-bold mb-[5rem]'>Extras</h3>
            <div className='flex flex-col w-full gap-[7rem] text-white'>
                <ExtraService
                    src={LightIcon}
                    title={lang === 'pt' ? 'Carros elétricos' : 'Electric Cars'}
                    text={lang === 'pt' ? 'Carregamento do carro para ter o carro pronto para a viagem na chegada' : 'Car charging to have the car ready for the trip upon arrival'}
                    price={texts?.eletric}
                />
                <ExtraService
                    src={WashIcon}
                    title={lang === 'pt' ? 'Lavagem' : 'Wash'}
                    text={lang === 'pt' ? 'Limpeza interna e externa do carro' : 'Interior and exterior car cleaning'}
                    price={texts?.wash}
                />
                <ExtraService
                    src={AnaliseIcon}
                    title={lang === 'pt' ? 'Inspeção' : 'Condition'}
                    text={lang === 'pt' ? 'Cuidamos da inspeção do carro e de quaisquer problemas que ele possa ter' : 'We take care of the car inspection and any problems it may have'}
                    price={texts?.condition}
                />
                <ExtraService
                    src={CareIcon}
                    title={lang === 'pt' ? 'CarHealth - Análise do carro' : 'CarHealth - Analyze the car'}
                    text={lang === 'pt' ? 'Analisamos o carro e vemos quais são as suas necessidades, entramos em contato com você e aconselhamos sobre o serviço ideal.' : 'We analyze the car and see what your needs are, contact you and advise you on the ideal service.'}
                    price={texts?.carHealth}
                />
                {/* <div className='w-full flex justify-center'>
                    <button className=' border-4 border-[var(--primary)] rounded-[10px] w-[15rem] py-4 font-bold text-white hover:bg-white hover:text-black transition-all duration-300' onClick={() => window.location.href="/book"}>{lang === 'pt' ? 'EXPLORAR MAIS...' : 'EXPLORE MORE...'}</button>
                </div> */}
            </div>
        </div>
    );
}

