import React, { useEffect, useState } from 'react';

import Logo from '../../../content/images/logo2.png';
import Image1 from '../../../content/images/header1.png';
import Background from '../../../content/images/bg-1.jpg';

export default function Header({ texts }: any) {

    const [lang, setLang] = useState("pt");

    const changeLanguage = (lang: string) => {
        localStorage.setItem("lang", lang);
        setLang(lang);
        window.location.reload();
    };

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    return (
        <div className='bg-[rgba(0,0,0,.5)] w-full min-h-screen px-[8vw] pt-[2rem] text-white bg-center bg-cover' style={{ backgroundImage: `url(${Background})` }}>
            <div className="absolute inset-0 bg-black bg-opacity-70 h-full w-full"></div>
            <div className='relative flex w-full justify-between items-center'>
                <img src={Logo} alt="logo" className='w-[10rem] quatro:w-[12rem] seis:w-[17rem]' />
                <p className='font-bold'><span className={`cursor-pointer ${lang === 'pt' ? 'text-[var(--primary)]' : ''}`} onClick={() => changeLanguage('pt')}>PT</span>&ensp;&ensp;|&ensp;&ensp;<span className={`cursor-pointer ${lang === 'en' ? 'text-[var(--primary)]' : ''}`} onClick={() => changeLanguage('en')}>EN</span></p>
            </div>
            <div className='relative flex justify-between mt-[5rem]'>
                <div className='flex flex-col gap-10 w-full'>
                    <h1 className='text-[2rem] seis:text-[2.5rem] font-bold'>{texts[lang].title}</h1>
                    <h2 className='text-balance text-[1.5rem] quatro:text-[2rem] seis:text-[2.5rem] font-poppinsM'>
                    {texts[lang].text}
                    </h2>
                    <div className='w-full flex flex-col quatro:flex-row gap-4'>
                        <button className='bg-[var(--primary)] rounded-[10px] w-full py-4 font-bold hover:brightness-90 transition-all duration-300' onClick={() => window.location.href="/contact"}>{lang === 'pt' ? 'CONTACTAR':'CONTACT'}</button>
                        <button onClick={() => window.location.href="/book"} className='bg-[var(--primary)] rounded-[10px] w-full py-4 font-bold hover:brightness-90 transition-all duration-300'>{lang === 'pt' ? 'RESERVAR':'BOOK'}</button>
                    </div>
                </div>
                <div className='w-full hidden justify-center items-end mil:flex'>
                    <img src={Image1} alt="header1" className='w-[25rem]' />
                </div>
            </div>
        </div>
    );
}

